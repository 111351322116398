<template>
  <b-form-group
    label="Talep Durumu"
    label-for="id_com_marketing_request_status"
  >
    <v-select
      v-model="dataItem.id_com_marketing_request_status"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Giriniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'MarketingRequestStatusCard',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['marketingRequestStatus/dataList']
    },
    dataItem() {
      return this.$store.getters['marketingRequest/dataItem']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('marketingRequestStatus/getDataList', {
        select: [
          'com_marketing_request_status.id AS id',
          'com_marketing_request_status.title AS title',
        ],
      })
    },
  },
}
</script>
