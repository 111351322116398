<template>
  <div>
    <b-list-group>
      <list-item
        title="Talep Kodu"
        :value="dataItem.rcode"
      />
      <list-item
        title="Talep Eden"
        :value="dataItem.username"
      />
      <list-item
        title="Talebi Oluşturan"
        :value="dataItem.request_user"
      />
      <list-item
        title="Atanan Yetkili"
        :value="dataItem.marketing_username"
      />
      <list-item
        title="Departman"
        :value="dataItem.department"
      />
      <list-item
        title="Talep Konusu"
        :value="dataItem.marketing_request_subject"
      />
      <list-item
        title="Talep Durumu"
        :value="dataItem.marketing_request_status"
      />
      <list-item
        title="Talep Önceliği"
        :value="dataItem.marketing_request_status_code"
      />
      <template v-if="userData.id_com_department !== '16' && userData.id_com_department !== '24'">
        <list-item
          title="Başlangıç Tarihi"
          :value="moment(dataItem.start_date).format('DD.MM.YYYY')"
        />
        <list-item
          title="Bitiş Tarihi"
          :value="moment(dataItem.finish_date).format('DD.MM.YYYY')"
        />
      </template>
      <b-list-group-item v-else>
        <div class="font-weight-bold text-primary">
          Tarihler
        </div>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <marketing-request-s-date-card />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <marketing-request-e-date-card />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-button
              block
              variant="primary"
              :disabled="savingDates || !(dataItem.start_date && dataItem.finish_date)"
              size="sm"
              @click="saveDates"
            >
              <b-spinner
                v-if="savingDates"
                small
              />
              <feather-icon
                v-if="!savingDates"
                icon="SaveIcon"
              /> Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>

import {
  BListGroup, BListGroupItem, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'
import MarketingRequestSDateCard from '@/views/Marketing/Requests/Elements/SdateCard.vue'
import MarketingRequestEDateCard from '@/views/Marketing/Requests/Elements/EdateCard.vue'

export default {
  name: 'MarketingRequestInfoCard',
  components: {
    MarketingRequestEDateCard, MarketingRequestSDateCard, BListGroupItem, BListGroup, ListItem, BRow, BCol, BButton, BSpinner,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      savingDates: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['marketingRequest/dataItem']
    },
  },
  methods: {
    saveDates() {
      if (this.dataItem.start_date || this.dataItem.finish_date) {
        this.savingDates = true
        this.$store.dispatch('marketingRequest/saveData', {
          id: this.dataItem.id,
          start_date: this.dataItem.start_date,
          finish_date: this.dataItem.finish_date,
        })
          .then(response => {
            if (response.status) {
              this.savingDates = false
            }
          })
      }
    },
  },
}
</script>
