<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <template #header>
                <h4>Talep Bilgileri</h4>
              </template>
              <marketing-request-info-card />
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <marketing-request-documents />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <template #header>
                <div>
                  <h4>Talep [#{{ itemData.rcode }}]</h4>
                  <div>
                    <small class="text-primary font-weight-bold">{{ itemData.title }}</small>
                  </div>
                </div>
                <div>
                  <b-badge variant="light-primary">
                    <feather-icon icon="SaveIcon" /> {{ moment(itemData.created).format('DD.MM.YYYY HH:mm:ss') }}
                  </b-badge>
                  <b-badge
                    v-if="itemData.modified"
                    variant="light-secondary"
                    class="ml-1"
                  >
                    <feather-icon icon="EditIcon" /> {{ moment(itemData.modified).format('DD.MM.YYYY HH:mm:ss') }}
                  </b-badge>
                </div>
              </template>
              <p>{{ itemData.content }}</p>
            </b-card>
          </b-col>
          <b-col
            v-if="userData.id_com_department === '16' || userData.id_com_department === '24'"
            cols="12"
            md="12"
          >
            <b-card title="Talep Durumu Güncelle">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <marketing-request-status-card />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-button
                    variant="primary"
                    :disabled="!itemData.id_com_marketing_request_status || updating"
                    @click="updateStatus"
                  >
                    <b-spinner
                      v-if="updating"
                      small
                    />
                    Güncelle
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-card title="Yanıtlar">
              <marketing-request-lines-card />
            </b-card>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BButton, BSpinner,
} from 'bootstrap-vue'
import MarketingRequestInfoCard from '@/views/Marketing/Requests/Components/RequestInfoCard.vue'
import MarketingRequestLinesCard from '@/views/Marketing/Requests/Components/LinesCard.vue'
import MarketingRequestStatusCard from '@/views/Marketing/Requests/Elements/StatusCards.vue'
import MarketingRequestDocuments from '@/views/Marketing/Requests/Components/DocumentsCard.vue'

export default {
  name: 'MarketingRequestViewItemForm',
  components: {
    MarketingRequestDocuments,
    MarketingRequestStatusCard,
    MarketingRequestLinesCard,
    MarketingRequestInfoCard,
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BSpinner,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      updating: false,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['marketingRequest/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('marketingRequest/getDataItem', this.$route.params.id)
    },
    updateStatus() {
      this.updating = true
      this.$store.dispatch('marketingRequest/saveData', {
        id: this.itemData.id,
        id_com_marketing_request_status: this.itemData.id_com_marketing_request_status,
      })
        .then(response => {
          if (response.status) {
            this.updating = false
            this.getData()
          }
        })
    },
  },
}
</script>
