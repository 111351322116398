<template>
  <div>
    <b-row>
      <template v-if="dataLoading">
        <b-col
          cols="12"
          md="12"
        >
          <loading />
        </b-col>
      </template>
      <template v-else>
        <template v-if="dataList.length > 0">
          <b-col
            v-for="(row,key) in dataList"
            :key="key"
            cols="12"
            md="12"
          >
            <div
              class="rounded mb-1 p-1"
              :class="userData.id === row.id_com_user ? 'bg-light-success text-success text-right' : 'bg-light-danger text-danger text-left'"
            >
              <p>{{ row.content }}</p>
              <div>
                <small><feather-icon icon="UserIcon" /> {{ row.username }}</small>
                <div>
                  <small><feather-icon icon="EditIcon" /> {{ moment(row.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
                </div>
              </div>
            </div>
          </b-col>
        </template>
        <template v-else>
          <b-col
            cols="12"
            md="12"
          >
            <b-alert
              show
              variant="info"
              class="m-2"
            >
              <div class="alert-body text-center">
                Henüz hiç yanıt eklenmemiş 👏
              </div>
            </b-alert>
          </b-col>
        </template>
      </template>
      <b-col>
        <hr>
        <b-form-textarea
          v-model="lineData.content"
          placeholder="Mesaj yaz.."
        />
        <div class="mt-1 text-right">
          <b-button
            variant="success"
            :disabled="!lineData.content || saving"
            @click="saveLine"
          >
            <b-spinner
              v-if="saving"
              small
            />
            <feather-icon
              v-if="!saving"
              icon="SendIcon"
            /> Gönder
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BBadge, BFormTextarea, BSpinner, BButton, BAlert,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'MarketingRequestLinesCard',
  components: {
    BAlert,
    Loading,
    BFormTextarea,
    BRow,
    BCol,
    BBadge,
    BSpinner,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      saving: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['marketingRequest/dataItem']
    },
    lineData() {
      return this.$store.getters['marketingRequestLine/dataItem']
    },
    dataList() {
      return this.$store.getters['marketingRequestLine/dataList']
    },
    dataLoading() {
      return this.$store.getters['marketingRequestLine/dataLoading']
    },
    saveData() {
      return this.$store.getters['marketingRequestLine/dataSaveStatus']
    },
  },
  created() {
    this.getDataLines()
  },
  methods: {
    getDataLines() {
      this.$store.dispatch('marketingRequestLine/getDataList', {
        where: {
          'com_marketing_request_line.id_com_marketing_request': this.$route.params.id,
        },
      })
    },
    saveLine() {
      if (this.lineData.content) {
        this.saving = true
        this.lineData.id_com_marketing_request = this.dataItem.id
        this.$store.dispatch('marketingRequestLine/saveData', this.lineData)
          .then(response => {
            if (response.status) {
              this.getDataLines()
              this.$store.dispatch('marketingRequestLine/resetDataItem')
              this.saving = false
            }
          })
      }
    },
  },
}
</script>
