<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Doküman Yükle</b-card-title>
    </b-card-header>
    <b-card-body>
      <pre-loading v-if="uploading" />
      <template v-else>
        <b-form-file
          v-model="dataItem.upload_file"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosya buraya bırakın..."
          browse-text="Dosya Seçin"
        />
        <b-form-input
          v-if="dataItem.upload_file"
          v-model="dataItem.title"
          class="mt-1"
          placeholder="Doküman başlığı girin.."
        />
        <div
          v-if="dataItem.upload_file"
          class="text-center mt-1"
        >
          <b-button
            variant="success"
            @click="uploadFile"
          >
            <FeatherIcon icon="UploadIcon" />
            Dosya Yükle
          </b-button>
        </div>
      </template>
    </b-card-body>
    <b-table-simple v-if="documents.length > 0">
      <b-thead>
        <b-tr>
          <b-th colspan="2">
            Yüklenen Dosyalar
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(file,index) in documents"
          :key="file.id"
        >
          <b-td>
            <b-link
              :href="baseURL + '/media/marketing_request/' + file.filename"
              target="_blank"
            >
              <FeatherIcon icon="PaperclipIcon" /> {{ file.title ? file.title : 'Dosya Eki '+index + 1 }}
            </b-link>
            <div>
              <small class="text-muted">{{ file.username }}</small>
            </div>
            <div>
              <small class="text-muted">{{ moment(file.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
            </div>
          </b-td>
          <b-td class="width-100 text-right">
            <b-button
              variant="flat-danger"
              class="btn-icon"
              pill
              @click="removeData(file.id)"
            >
              <FeatherIcon icon="TrashIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormFile,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BButton,
  BLink,
  BFormInput,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'MarketingRequestDocuments',
  components: {
    BFormInput,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormFile,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BLink,
    PreLoading,
  },
  data() {
    return {
      upload_file: null,
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    marketingRequest() {
      return this.$store.getters['marketingRequest/dataItem']
    },
    dataItem() {
      return this.$store.getters['marketingRequestDocuments/dataItem']
    },
    documents() {
      return this.$store.getters['marketingRequestDocuments/dataList']
    },
    uploading() {
      return this.$store.getters['marketingRequestDocuments/uploading']
    },
  },
  created() {
    this.getDocuments()
  },
  methods: {
    getDocuments() {
      this.$store.dispatch('marketingRequestDocuments/getDataList', {
        where: {
          'com_marketing_request_documents.id_com_marketing_request': this.$route.params.id,
        },
      })
    },
    removeData(id) {
      this.$store.dispatch('marketingRequestDocuments/removeData', id)
        .then(response => {
          if (response) {
            this.getDocuments()
          }
        })
    },
    uploadFile() {
      this.$store.dispatch('marketingRequestDocuments/saveData', {
        title: this.dataItem.title,
        id_com_marketing_request: this.marketingRequest.id,
        upload_file: this.dataItem.upload_file,
      })
        .then(response => {
          if (response.status) {
            this.documents.push({
              id: response.id,
              filename: response.filename,
              title: response.title,
            })
            this.dataItem.upload_file = null
          }
        })
    },
  },
}
</script>
